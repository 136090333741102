<template>
  <div class="barrier-card">
    <div>
      <b-icon
        class="barrier-icon"
        :style='{"color":"#424242"}'
        :icon="isOpen ? 'check' : 'clock'"
      />
    </div>
    <div>
      <div class="barrier-label">
        {{ fieldData.label }}
      </div>
      <div class="barrier-description" v-html="fieldData.content" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "BarrierField",
  props: {
    fieldId: {
      type: Number,
      required: true,
    },
    questionId: {
      type: Number,
      required: true,
    },
    isMortgage: {
      type: Boolean,
      required: false,
      default: false,
    },
    loanApplicationId: {
      type: [Number, String],
      required: false,
    },
    mortgageApplicationId: {
      type: [Number, String],
      required: false,
    },
  },
  data() {
    return {
      fieldData: {},
    };
  },
  computed: {
    isOpen() {
      if (this.isMortgage) {
        return this.$store.getters["mortgageApplication/isBarrierOpen"](
          this.mortgageApplicationId,
          this.questionId
        );
      } else {
        return this.$store.getters["loanApplication/isBarrierOpen"](
          this.loanApplicationId,
          this.questionId
        );
      }
    },
  },
  async created() {
    await this.fetchData(this.fieldId);
    this.fieldData = this.$store.getters["specialFieldStore/get"](this.fieldId);
    if (this.isMortgage) {
      await this.checkMortgage({
        questionId: this.questionId,
        applicationId: this.mortgageApplicationId,
      });
    } else {
      await this.checkLoan({
        questionId: this.questionId,
        applicationId: this.loanApplicationId,
      });
    }
  },
  methods: {
    ...mapActions({
      checkMortgage: "mortgageApplication/checkBarrier",
      checkLoan: "loanApplication/checkBarrier",
      fetchData: "specialFieldStore/fetchOne",
    }),
  },
};
</script>

<style scoped lang="scss">
.barrier-card {
  background: white;
  border-radius: 32px;
  padding: 24px;
  display: flex;
  gap: 8px;
}

.barrier-label {
  color: #424242;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 8px;
}

.barrier-description {
  color: #424242;
  font-size: 16pp;
}

.barrier-icon {
  font-size: 16px;
}

@media only screen and (min-width: 769px) {
  .barrier-icon {
    font-size: 64px;
  }

  .barrier-card {
    padding: 32px;
    gap: 32px;
  }
}
</style>
